import * as rax from 'retry-axios'
import { appInsights } from '../../AppInsights'
//import config from '../../config'
import AccountStore from './Account'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import Axios from 'axios'
import _ from 'lodash'
import { toJS } from 'mobx'
import queryString from 'query-string'

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  paramsSerializer: (params) => {
    const filters = _.mapValues(params, (value) => toJS(value))
    return queryString.stringify(filters, { arrayFormat: 'bracket' })
  }
})

// axios.defaults.raxConfig = {
//   instance: axios,
//   retryDelay: 3000,
//   statusCodesToRetry: [[401]],
//   onRetryAttempt: (err) => {
//     const cfg = rax.getConfig(err)
//     //console.log(`Retry attempt #${cfg.currentRetryAttempt}`)
//   }
// }

axios.interceptors.request.use((config) => {
  if (
    AccountStore.meta.isAuthenticated &&
    AccountStore.isSharedLogin &&
    config.url != '/users/me' &&
    config.url != '/users/logout'
  ) {
    AccountStore.prolongKomatsuSession()
  }

  if (AccountStore.meta.isAuthenticated && config.url != '/users/logout') {
    AccountStore.setLastRequestTimestamp()
  }

  return config
})

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (AccountStore.isSharedLogin && error.response.status === 401) {
      AccountStore.sharedLogin()
    }
    appInsights.trackException({
      error: new Error(error.response && error.response.statusText),
      severityLevel: SeverityLevel.Error
    })
    return Promise.reject(error)
  }
)

rax.attach(axios)

export function setApiToken(token: string) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export default axios
