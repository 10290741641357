import routes from './_routes'
import { RouteHandler, ScrollToTop } from './components'
import AccountStore from './stores/Account'
import CssBaseline from '@material-ui/core/CssBaseline'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'

import { createTheme } from '@material-ui/core/styles'
import { Shadows } from '@material-ui/core/styles/shadows'
import { ThemeProvider } from '@material-ui/styles'

type Contrast = 'light' | 'dark'

const primary = 'rgb(20, 10, 154)'

const primaryColor = {
  50: primary,
  100: primary,
  200: primary,
  300: primary,
  400: primary,
  500: primary,
  600: primary,
  700: primary,
  800: primary,
  900: primary,
  A100: primary,
  A200: 'white',
  A400: 'white',
  A700: 'white',
  contrastDefaultColor: 'light' as Contrast
}

const primaryColorCastrol = {
  50: '#00833b',
  100: '#00833b',
  200: '#00833b',
  300: '#00833b',
  400: '#00833b',
  500: '#00833b',
  600: '#00833b',
  700: '#00833b',
  800: '#00833b',
  900: '#00833b',
  A100: 'rgb(192, 193, 247)',
  A200: 'white',
  A400: 'white',
  A700: 'white',
  contrastDefaultColor: 'light' as Contrast
}

const secondaryColorCastrol = {
  50: '#f5564a',
  100: '#f5564a',
  200: '#f5564a',
  300: '#f5564a',
  400: '#f5564a',
  500: '#f5564a',
  600: '#f5564a',
  700: '#f5564a',
  800: '#f5564a',
  900: '#f5564a',
  A100: '#f5564a',
  A200: '#f5564a',
  A400: '#f5564a',
  A700: '#f5564a',
  contrastDefaultColor: 'dark' as Contrast
}

const secondaryColor = {
  50: '#ffd700',
  100: '#ffd700',
  200: '#ffd700',
  300: '#ffd700',
  400: '#ffd700',
  500: '#ffd700',
  600: '#ffd700',
  700: '#ffd700',
  800: '#ffd700',
  900: '#ffd700',
  A100: '#ffd700',
  A200: '#ffd700',
  A400: '#ffd700',
  A700: '#ffd700',
  contrastDefaultColor: 'dark' as Contrast
}

const grey = {
  50: '#F9FAFB',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D1D5DB',
  400: '#9CA3AF',
  500: '#6B7280',
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: '#111827'
}

const App = observer(
  class App extends React.Component {
    theme: any = null

    constructor(props: any) {
      super(props)

      makeObservable(this, {
        theme: observable
      })
    }

    componentDidMount = action(() => {
      if (
        !_.includes(window.location.pathname, '/staff') &&
        !AccountStore.isCastrol &&
        !_.includes(window.location.pathname, '/logged-out') &&
        !_.includes(window.location.pathname, '/login-failed')
      ) {
        AccountStore.isSharedLogin = true
      }
      AccountStore.getProfile()

      this.theme = createTheme({
        overrides: {
          MuiTypography: {
            subtitle1: {
              fontWeight: 'bold'
            }
          },
          // make dialogs look better on mobile
          MuiDialog: {
            paperFullWidth: {
              margin: 0,
              width: 'calc(100% - 48px)'
            }
          },
          MuiButton: {
            root: {
              textTransform: 'none'
            }
          },
          MuiTab: {
            root: {
              textTransform: 'none'
            }
          }
        },
        // basics
        // shadows: Array.from({ length: 25 }).fill('none') as Shadows,
        typography: {
          fontFamily: ['Noto Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: 13,
          fontWeightRegular: 400,
          fontWeightBold: 600
          // h1: {
          //   fontSize: 28,
          //   fontWeight: 600,
          //   lineHeight: '40px'
          // },
          // h2: {
          //   fontSize: 20,
          //   fontWeight: 600,
          //   lineHeight: '24px'
          // },
          // h3: {
          //   fontSize: 17,
          //   fontWeight: 600,
          //   lineHeight: '24px'
          // },
          // h4: {
          //   fontSize: 15,
          //   fontWeight: 600,
          //   lineHeight: '24px'
          // },
          // body1: {
          //   fontWeight: 600,
          //   fontSize: '13px',
          //   lineHeight: '24px',
          //   color: '#31363E'
          // },
          // body2: {
          //   fontWeight: 400,
          //   fontSize: '12px',
          //   lineHeight: '16px',
          //   letterSpacing: '0.2px',
          //   color: grey[500]
          // },
          // small: {
          //   color: '#A09FA7',
          //   fontSize: 13
          // },
          // mini: {
          //   fontSize: 12,
          //   letterSpacing: 0.2,
          //   color: grey[500],
          //   lineHeight: '16px'
          // }
          // disable variants not in the design system
          // h5: undefined,
          // h6: undefined,
          // subtitle1: undefined,
          // subtitle2: undefined,
          // caption: undefined,
          // overline: undefined
        },
        palette: {
          type: 'light',
          primary: AccountStore.isCastrol ? primaryColorCastrol : primaryColor,
          secondary: AccountStore.isCastrol ? secondaryColorCastrol : secondaryColor
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 650,
            md: 960,
            lg: 1280,
            xl: 1920
          }
        }
      })

      // Castrol modifications
      if (AccountStore.isCastrol) {
        document.body.classList.add('castrol')
        document.title = 'Castrol Labcheck'
      } else {
        document.title = 'CMS | myKomatsu'
      }
    })

    render() {
      return (
        <Router basename={!AccountStore.isCastrol ? '/cms' : '/'}>
          <LastLocationProvider>
            <ScrollToTop>
              {this.theme && (
                <ThemeProvider theme={this.theme}>
                  <CssBaseline />
                  <RouteHandler
                    isAuthenticated={AccountStore.meta.isAuthenticated}
                    routes={routes}
                  />
                </ThemeProvider>
              )}
            </ScrollToTop>
          </LastLocationProvider>
        </Router>
      )
    }
  }
)

export default App
