import API from '../API'
import { CreateNote, Meta, Note } from './index.d'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'

import resizeFile from 'app/utils/resizeFile'

class NoteStore {
  notes: Note[] = []
  meta: Meta = {
    pending: false,
    retrieved: false
  }

  constructor() {
    makeObservable(this, {
      notes: observable,
      meta: observable,
      getAll: action,
      createNote: action,
      reset: action
    })
  }

  getAll(filters: Object) {
    this.meta.retrieved = false
    this.meta.pending = true

    return API.get('/notes', { params: filters })
      .then(async (res) => {
        this.notes = res.data
      })
      .finally(() => {
        this.meta.retrieved = true
        this.meta.pending = false
      })
  }

  createNote(note: CreateNote) {
    let { attachments, ...otherFields } = note
    let bodyFormData = new FormData()
    _.forEach(otherFields, (val, key) => {
      val && bodyFormData.append(key, '' + val)
    })

    return Promise.all(
      attachments.map(async (attachment) => {
        if (_.includes(['image/jpeg', 'image/png'], attachment.type)) {
          const compressed = (await resizeFile(attachment)) as Blob
          bodyFormData.append('formFile', compressed)
        } else {
          bodyFormData.append('formFile', attachment)
        }
      })
    ).then(() => API.post('/notes', bodyFormData))
  }

  reset() {
    this.notes = []
    this.meta = {
      pending: false,
      retrieved: false
    }
  }
}

export default new NoteStore()
