import { appInsights } from '../../../AppInsights'
import API from '../API'
import { Component, Customer, Meta, Registration, RegistrationCard, Unit } from './index.d'
import _ from 'lodash'
import { action, makeObservable, observable, toJS } from 'mobx'

class RegistrationCardStore {
  registrations: Array<Registration> = []
  customers: Array<Customer> = []
  units: Array<Unit> = []
  components: Array<Component> = []
  meta: Meta = {
    loadingRegistrations: false,
    loadingCustomers: false,
    loadingComponents: false,
    loadingUnits: false
  }

  constructor() {
    makeObservable(this, {
      registrations: observable,
      customers: observable,
      units: observable,
      components: observable,
      meta: observable,
      getRegistrations: action,
      removeRegistrations: action,
      getCustomers: action,
      getCustomerUnits: action,
      getUnitComponents: action
    })
  }

  getRegistrations(filters: Object = {}) {
    this.meta.loadingRegistrations = true

    return API.get('/registrations', { params: filters }) // TODO: implement pagination
      .then(
        action((res) => {
          this.meta.loadingRegistrations = false
          this.registrations = res.data
        })
      )
  }

  removeRegistrations(registrationIds: number[], filters: Object = {}, deleteAll: boolean) {
    return API.delete('/registrations', {
      params: {
        registrationIds: deleteAll ? [] : registrationIds,
        deleteAll,
        ...filters
      }
    }).then(
      action(
        () =>
          (this.registrations = this.registrations.filter(
            (registration) => !registrationIds.includes(registration.registrationId)
          ))
      )
    )
  }

  async getCustomers() {
    this.customers = []
    this.meta.loadingCustomers = true

    return API.get('/registrations/customers').then(
      action((res) => {
        this.meta.loadingCustomers = false
        this.customers = res.data
      })
    )
  }

  async getCustomerUnits(customers: number[]) {
    this.units = []
    if (!customers.length) return
    this.meta.loadingUnits = true

    return API.get('/registrations/units', { params: { customers } }).then(
      action((res) => {
        this.meta.loadingUnits = false
        this.units = res.data
      })
    )
  }

  async getUnitComponents(unitId: number) {
    this.components = []
    if (!unitId) return
    this.meta.loadingComponents = true

    return API.get('/registrations/components', { params: { units: [unitId] } }).then(
      action((res) => {
        this.meta.loadingComponents = false
        this.components = res.data
      })
    )
  }

  async getComponentTemplates(unitMake: string, unitModel: string) {
    if (!unitMake || !unitModel) return

    return API.get('/registrations/components/templates', { params: { unitMake, unitModel } }).then(
      action((res) => {
        this.components = res.data
      })
    )
  }

  getUnitLastChanged(unitId: number) {
    return API.get(`/registrations/units/${unitId}/lastChanged`)
  }

  create(registrationCard: RegistrationCard) {
    const {
      coolantComponents,
      greaseComponents,
      oilComponents,
      wastewaterComponents,
      fuelComponents,
      adblueComponents,
      metalworkingComponents
    } = registrationCard

    const components = _.flatten(
      [
        coolantComponents,
        greaseComponents,
        oilComponents,
        wastewaterComponents,
        fuelComponents,
        adblueComponents,
        metalworkingComponents
      ].map((item) => toJS(item))
    )

    appInsights.trackEvent({
      name: 'create-card-store',
      properties: { card: toJS(registrationCard), components }
    })
    return API.post('/registrations', {
      ...registrationCard,
      components
    })
  }

  reset() {
    this.customers = []
    this.units = []
    this.components = []
    this.meta = {
      loadingRegistrations: false,
      loadingCustomers: false,
      loadingComponents: false,
      loadingUnits: false
    }
  }
}

export default new RegistrationCardStore()
