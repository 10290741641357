import API from '../../stores/API'
import MetadataStore from '../Metadata'
import NoteStore from '../Note'
import RegistrationCardStore from '../RegistrationCard'
import ReportStore from '../Report'
import SampleStore from '../Sample'
import { ContactScope, IUpdatePassword, Meta, UpdateUser, User } from './index.d'
import Axios from 'axios'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'

const EXPIRATION_DIALOG_TIMEOUT = 27 * 1000 * 60
export const EXPIRATION_TIMEOUT = 29 * 1000 * 60
class AccountStore {
  isCastrol: boolean =
    process.env.REACT_APP_IS_CASTROL == 'true' ||
    _.includes(window.location.href, process.env.REACT_APP_LABCHECK_URL)
  isNewZealand: boolean =
    process.env.REACT_APP_IS_NEWZEALAND == 'true' || _.includes(window.location.href, '.co.nz')
  settings = {
    myKomatsuUrl: (process.env.REACT_APP_PORTAL_KOMATSU_URL || 'https://my.komatsu.com.au').replace(
      'com.au',
      this.isNewZealand ? 'co.nz' : 'com.au'
    ),
    registrationReportLink: this.isCastrol
      ? 'Labcheck Registration Cards'
      : 'KOWA Registration Cards',
    latestMachineReportLink: this.isCastrol ? 'bad-report' : 'KOWA Latest Machine Report'
  }
  user?: User = undefined
  isSharedLogin: boolean = false
  meta: Meta = {
    retrieved: false,
    isAuthenticated: false,
    isLoggedOut: false,
    pending: false,
    error: '',
    isSessionAboutToExpire: false,
    sessionExpired: false
  }
  lastRequestTimestamp: number | null = null
  scopes: ContactScope[] = []

  constructor() {
    makeObservable(this, {
      user: observable,
      isSharedLogin: observable,
      meta: observable,
      lastRequestTimestamp: observable,
      scopes: observable,
      getProfile: action,
      getScopes: action,
      updateProfile: action,
      login: action,
      forgotPassword: action,
      resetPassword: action,
      sharedLogin: action,
      logout: action,
      reset: action
    })

    setInterval(() => this.checkSessionExpiration(), 1000)
  }

  getProfile() {
    return API.get('/users/me')
      .then(
        action(async (res) => {
          this.user = res.data
          this.meta.isAuthenticated = true
          this.isSharedLogin = res.data.isSharedLogin
        })
      )
      .catch(() => {
        this.isSharedLogin && this.sharedLogin()
      })
      .finally(
        action(() => {
          this.meta.retrieved = true
        })
      )
  }

  getScopes() {
    return API.get('/users/me/scopes').then(action((res) => (this.scopes = res.data)))
  }

  updateProfile(values: UpdateUser) {
    return API.put('/users/me', values).then(action((res) => (this.user = res.data)))
  }

  // move to own store when more user routes are available
  getUser(id: number): Promise<User> {
    return API.get(`/users/primary/${id}`).then(action((res) => res.data))
  }

  updatePassword(passwords: IUpdatePassword) {
    this.meta.pending = true

    return API.patch('/users/me/password', passwords).then(
      action(async () => {
        setTimeout(() => {
          if (this.user) {
            this.user.isFirstLogin = false
          }
        }, 1000)

        return Promise.resolve()
      })
    )
  }

  isSuperUser() {
    if (!this.user) return

    return this.user.contactRoleId === 2
    // return this.user.contactRole === 'User'
  }

  checkSessionExpiration() {
    if (this.lastRequestTimestamp && Date.now() - this.lastRequestTimestamp > EXPIRATION_TIMEOUT) {
      this.meta.sessionExpired = true
      this.logout(true)
    } else if (
      this.lastRequestTimestamp &&
      Date.now() - this.lastRequestTimestamp > EXPIRATION_DIALOG_TIMEOUT
    ) {
      this.meta.isSessionAboutToExpire = true
    } else if (this.meta.isSessionAboutToExpire) {
      this.meta.isSessionAboutToExpire = false
    }
  }

  setLastRequestTimestamp() {
    if (!this.meta.isAuthenticated) {
      return
    }
    this.lastRequestTimestamp = Date.now()
  }

  resetLastRequestTimeout() {
    this.lastRequestTimestamp = null
  }

  updateEmailMarketing(optInEmailsMarketing: boolean | undefined) {
    if (!this.user) return
    const updateUser: UpdateUser | undefined = {
      ...this.user,
      street1: null,
      city: null,
      state: null,
      optInEmailsMarketing
    }
    return this.updateProfile(updateUser)
  }

  login(credentials: { username: string; password: string }) {
    this.meta.pending = true
    const url = this.isCastrol ? '/users/authenticate-labcheck' : '/users/authenticate'

    return API.post(url, credentials)
      .then(
        action(async () => {
          this.meta = {
            isAuthenticated: true,
            retrieved: true,
            isLoggedOut: false,
            pending: false,
            isSessionAboutToExpire: false,
            sessionExpired: false,
            error: ''
          }
          this.lastRequestTimestamp = Date.now()
          this.getProfile()
          this.isSharedLogin = false
        })
      )
      .catch(
        action((e) => {
          this.meta = {
            isAuthenticated: false,
            retrieved: true,
            pending: false,
            isLoggedOut: false,
            isSessionAboutToExpire: false,
            sessionExpired: false,
            error: e.response.data.error || 'Some error occured'
          }
        })
      )
  }

  forgotPassword(email: string, reCaptcha: string) {
    this.meta.pending = true

    return API.post('/users/forgot/labcheck', { email, reCaptcha })
      .then(
        action(async () => {
          this.meta.retrieved = true
          this.meta.pending = false
          this.meta.error = ''
        })
      )
      .catch(
        action((e) => {
          this.meta.retrieved = true
          this.meta.pending = false
          this.meta.error = e.response.data.error || 'Some error occured'
        })
      )
  }

  resetPassword(newPassword: string, resetToken: string) {
    this.meta.pending = true

    return API.post('/users/reset/labcheck', { newPassword, resetToken })
      .then(
        action(async () => {
          this.meta.retrieved = true
          this.meta.pending = false
          this.meta.error = ''
        })
      )
      .catch(
        action((e) => {
          this.meta.retrieved = true
          this.meta.pending = false
          this.meta.error = e.response.data.error || 'Some error occured'
        })
      )
  }

  sharedLogin() {
    this.meta.pending = true
    this.isSharedLogin = true

    return API.get('/users/authenticate-shared')
      .then(
        action(async () => {
          this.meta = {
            isAuthenticated: true,
            retrieved: true,
            isLoggedOut: false,
            pending: false,
            isSessionAboutToExpire: false,
            sessionExpired: false,
            error: ''
          }
          this.getProfile()
        })
      )
      .catch((e) => {
        if (_.includes(e.response.data.error, 'User Contact not found')) {
          window.location.replace(
            `${this.settings.myKomatsuUrl}/condition-monitoring/how-to-get-started`
          )
          return
        }
        window.location.replace(
          `${this.settings.myKomatsuUrl}/login?returnUrl=${window.location.pathname.replace(
            '/staff',
            ''
          )}`
        )
      })
  }

  logout(isForceLoggedOut = false) {
    if (this.isSharedLogin) {
      Axios.get(`${this.settings.myKomatsuUrl}/logoff`, { maxRedirects: 0 })
    }

    return API.get('/users/logout').then(async () => this.resetStores(isForceLoggedOut))
  }

  prolongKomatsuSession() {
    Axios.get(`${this.settings.myKomatsuUrl}/api/account/getcontactuscommerceuserinformation`)
      .then((res) => {
        if (!res.data) {
          this.logout(true)
        }
      })
      .catch(() => this.logout(true))
  }

  reset(isForceLoggedOut: boolean) {
    this.user = undefined
    this.meta = {
      retrieved: true,
      isAuthenticated: false,
      isLoggedOut: !isForceLoggedOut,
      pending: false,
      isSessionAboutToExpire: false,
      sessionExpired: false,
      error: ''
    }
    this.lastRequestTimestamp = null
  }

  resetStores(isForceLoggedOut: boolean) {
    this.reset(isForceLoggedOut)
    MetadataStore.reset()
    SampleStore.reset()
    ReportStore.reset()
    RegistrationCardStore.reset()
    NoteStore.reset()
  }
}

export default new AccountStore()
