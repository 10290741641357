import { lazy } from 'react'
import { RouteProps } from 'react-router-dom'

const routes: RouteProps[] = [
  {
    path: '/',
    component: lazy(() => import('./views/LoggedIn'))
  },
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/InternalLogin'))
  },
  {
    path: '/logged-out',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/LoggedOut'))
  },
  {
    path: '/login-failed',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/LoginFailed'))
  },
  {
    path: '/staff',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/InternalLogin'))
  },
  {
    path: '/reset-password',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/ResetPassword'))
  },
  {
    path: '/reset-password/:token',
    exact: true,
    component: lazy(() => import('./views/LoggedOut/ResetPassword/NewPassword'))
  }
]

export default routes
