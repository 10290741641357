import API from '../API'
import { Meta, Sample, SampleLinkage, Template } from './index.d'
import Axios from 'axios'
import { includes } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

class SampleStore {
  samples: Sample[] = []
  templates: Template[] = []
  sampleLinkages: SampleLinkage[] | null = null
  meta: Meta = {
    pending: false,
    retrieved: false
  }
  cancelTokenSource: any = null

  constructor() {
    makeObservable(this, {
      samples: observable,
      templates: observable,
      sampleLinkages: observable,
      meta: observable,
      cancelTokenSource: observable,
      getAll: action,
      getLatest: action,
      resetSamples: action,
      getLinkages: action,
      createLinkage: action,
      removeLinkage: action,
      getSample: action,
      getHistory: action,
      getChartSamples: action,
      getChartTemplates: action,
      createChartTemplate: action,
      removeChartTemplate: action,
      sendMessage: action,
      reset: action
    })
  }

  getAll(filters: Object) {
    this.resetSamples()
    this.meta.pending = true

    return API.get('/samples', { params: filters })
      .then(
        action(async (res) => {
          this.samples = res.data
        })
      )
      .finally(
        action(() => {
          this.meta.retrieved = true
          this.meta.pending = false
        })
      )
  }

  getLatest(filters: Object) {
    this.resetSamples()
    this.meta.pending = true
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Operation canceled by the user.')
    }
    this.cancelTokenSource = Axios.CancelToken.source()
    let isCanceled = false

    return API.get('/samples/latest', {
      params: filters,
      cancelToken: this.cancelTokenSource.token
    })
      .then(
        action(async (res) => {
          this.meta.retrieved = true
          this.samples = res.data
        })
      )
      .catch(
        action((thrown) => {
          if (Axios.isCancel(thrown)) {
            isCanceled = true
            return
          }
          this.meta.retrieved = false
        })
      )
      .finally(
        action(() => {
          if (isCanceled) {
            isCanceled = false
            return
          }
          this.meta.pending = false
        })
      )
  }

  resetSamples() {
    this.meta.retrieved = false
    this.samples = []
  }

  getLinkages() {
    return API.get('/samples/latest/linkages').then(
      action(async (res) => (this.sampleLinkages = res.data))
    )
  }

  createLinkage(customerIds: number[]) {
    return API.post('/samples/latest/linkages', { customerInclusions: customerIds }).then(
      action(async (res) => {
        this.sampleLinkages = res.data
        return res.data
      })
    )
  }

  removeLinkage(customerId: number) {
    return API.delete('/samples/latest/linkages', {
      params: { customerInclusions: customerId }
    }).then(
      action(async (res) => {
        this.sampleLinkages = res.data
        return res.data
      })
    )
  }

  resetMetadata() {
    this.meta.retrieved = false
    this.meta.pending = false
  }

  getSample(id: number) {
    return API.get(`/samples/${id}`)
  }

  getHistory(id: number, showAll: boolean = false) {
    return API.get(`/samples/${id}/history`, { params: { showAll } })
  }

  // remove from latest samples
  actionSample(id: number, processed: boolean) {
    return API.put(`/samples/${id}/processed`, { processed }).then(
      () => (this.samples = this.samples.filter((sample) => id !== sample.sampleNumber))
    )
  }

  // remove multiple samples from latest samples
  actionSamples(sampleNumbers: number[], processed: boolean = true) {
    return API.put(`/samples/processed`, { sampleNumbers, processed }).then(
      action(
        () =>
          (this.samples = this.samples.filter(
            (sample) => !includes(sampleNumbers, sample.sampleNumber)
          ))
      )
    )
  }

  getChartSamples(filters: Object) {
    return API.get('/samples/advanced', { params: filters })
  }

  getChartTemplates() {
    return API.get('/samples/graph/templates').then(
      action(async (res) => {
        this.templates = res.data
      })
    )
  }

  createChartTemplate(name: string, ids: number[]) {
    const template = {
      testItemIdList: ids,
      name,
      added: new Date(),
      addedBy: 'anonym'
    }

    return API.post('/samples/graph/templates', template).then(
      action(async (res) => {
        this.templates.push(res.data)
        return res.data
      })
    )
  }

  updateChartTemplate(id: number, name: string) {
    return API.put(`/samples/graph/templates/${id}`, { name }).then(
      action(() => {
        this.templates = this.templates.map((template) => {
          if (template.graphTemplateId === id) {
            template.name = name
          }
          return template
        })
      })
    )
  }

  removeChartTemplate(id: number) {
    return API.delete(`/samples/graph/templates/${id}`).then(
      action(
        () =>
          (this.templates = this.templates.filter((template) => template.graphTemplateId !== id))
      )
    )
  }

  sendMessage(
    message: string,
    customer: string,
    unit: string,
    component: string,
    sampleNumber: number
  ) {
    return API.post('/samples/message', {
      message,
      customer,
      unit,
      component,
      sampleNumber
    })
  }

  reset() {
    this.samples = []
    this.templates = []
    this.sampleLinkages = null
    this.meta = {
      pending: false,
      retrieved: false
    }
  }
}

export default new SampleStore()
