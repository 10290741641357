import Resizer from 'react-image-file-resizer'

export default function resizeFile(file: Blob): Promise<string | Blob | ProgressEvent<FileReader>> {
  let quality = 95

  //4MB image file
  if (file.size > 4_000_000) {
    quality = 90
  }
  //8MB image file
  if (file.size > 8_000_000) {
    quality = 85
  }

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1400,
      1000,
      'JPEG',
      quality,
      0,
      (uri) => {
        resolve(uri)
      },
      'blob'
    )
  })
}
