import API from '../API'
import {
  AdvancedSearchMetadata,
  Component,
  Customer,
  Meta,
  Metadata,
  Site,
  Unit,
  UpdateDetails
} from './index.d'
import Axios from 'axios'
import { action, configure, makeObservable, observable } from 'mobx'

configure({ useProxies: 'never' })

class MetadataStore {
  metadata: Metadata = {
    products: [],
    componentTypes: [],
    fluidTypes: [],
    siteSets: [],
    parents: [],
    websiteMenus: [],
    websiteNews: [],
    contactEmailOptions: [],
    reportNames: [],
    measures: [],
    reportingRanks: [],
    serviceTypes: [],
    shoppingLink: ''
  }
  advancedSearchMetadata: AdvancedSearchMetadata = {
    uniqueComponents: [],
    uniqueFluidMakes: [],
    uniqueFluidTypes: [],
    uniqueFluidGrades: []
  }
  customers: Customer[] = []
  sites: Site[] = []
  units: Unit[] = []
  components: Component[] = []
  meta: Meta = {
    loadingMetadata: false,
    loadingAdvancedSearchMetadata: false,
    loadingCustomers: false,
    loadingComponents: false,
    loadingSites: false,
    loadingUnits: false
  }
  cancelTokenSource: any = null

  constructor() {
    makeObservable(this, {
      metadata: observable,
      advancedSearchMetadata: observable,
      customers: observable,
      sites: observable,
      units: observable,
      components: observable,
      cancelTokenSource: observable,
      meta: observable,
      getMetadata: action,
      getAdvancedSearchMetadata: action,
      getCustomers: action,
      getSites: action,
      getAllSites: action,
      getComponents: action,
      getUnits: action,
      getAllUnits: action,
      reset: action
    })
  }

  getMetadata() {
    this.meta.loadingMetadata = true

    return API.get('/metadata').then(
      action(async (res) => {
        this.metadata = res.data
        this.meta.loadingMetadata = false
      })
    )
  }

  getAdvancedSearchMetadata() {
    this.meta.loadingAdvancedSearchMetadata = true

    return API.get('/metadata/advanced-search').then(async (res) => {
      this.advancedSearchMetadata = res.data
      this.meta.loadingAdvancedSearchMetadata = false
    })
  }

  getCustomers() {
    this.meta.loadingCustomers = true

    return API.get('/metadata/customers').then(
      action(async (res) => {
        this.meta.loadingCustomers = false
        this.customers = res.data
      })
    )
  }

  getCustomer(id: number): Promise<Customer> {
    return API.get(`/metadata/customers/${id}`).then((res) => res.data)
  }

  getSites(customers: number[] = []) {
    this.sites = []
    if (!customers.length) return
    this.meta.loadingSites = true

    return API.get('/metadata/sites', { params: { customers } }).then(
      action(async (res) => {
        this.meta.loadingSites = false
        this.sites = res.data
      })
    )
  }

  // duplicite? remove
  getAllSites = action(() => {
    this.sites = []
    this.meta.loadingSites = true

    return API.get('/metadata/sites', { params: {} }).then(
      action(async (res) => {
        this.meta.loadingSites = false
        this.sites = res.data
      })
    )
  })

  getSite(id: number): Promise<Site> {
    return API.get(`/metadata/sites/${id}`).then((res) => res.data)
  }

  getComponents(customers: number[] = [], units: number[] = [], noLastChanged: boolean = false) {
    this.components = []
    if (!customers.length && !units.length) return
    this.meta.loadingComponents = true

    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Operation canceled by the user.')
    }
    this.cancelTokenSource = Axios.CancelToken.source()
    let isCanceled = false
    return API.get('/metadata/components', {
      params: { customers, units, noLastChanged },
      cancelToken: this.cancelTokenSource.token
    })
      .then(async (res) => {
        this.meta.loadingComponents = false
        this.components = res.data
      })
      .catch(
        action((thrown) => {
          if (Axios.isCancel(thrown)) {
            isCanceled = true
            return
          }
          this.meta.loadingComponents = false
        })
      )
      .finally(
        action(() => {
          if (isCanceled) {
            isCanceled = false
            return
          }
          this.meta.loadingComponents = false
        })
      )
  }

  getUnits(customers: number[] = []) {
    this.units = []
    if (!customers.length) return
    this.meta.loadingUnits = true

    return API.get('/metadata/units', { params: { customers } }).then(
      action(async (res) => {
        this.meta.loadingUnits = false
        this.units = res.data
      })
    )
  }

  getUnit(id: number, customerId: number): Promise<Unit> {
    return API.get(`/metadata/customers/${customerId}/units/${id}`).then((res) => res.data)
  }

  getAllUnits() {
    this.units = []
    this.meta.loadingUnits = true

    return API.get('/metadata/units', { params: {} }).then(async (res) => {
      this.meta.loadingUnits = false
      this.units = res.data
    })
  }

  updateDetails(details: UpdateDetails) {
    return API.post('/update-details', details)
  }

  reset() {
    this.metadata = {
      products: [],
      componentTypes: [],
      fluidTypes: [],
      siteSets: [],
      parents: [],
      websiteMenus: [],
      websiteNews: [],
      contactEmailOptions: [],
      reportNames: [],
      measures: [],
      reportingRanks: [],
      serviceTypes: [],
      shoppingLink: ''
    }
    this.advancedSearchMetadata = {
      uniqueComponents: [],
      uniqueFluidMakes: [],
      uniqueFluidTypes: [],
      uniqueFluidGrades: []
    }
    this.customers = []
    this.sites = []
    this.units = []
    this.components = []
  }
}

export default new MetadataStore()
