import {
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import Autosuggest from 'react-autosuggest'

import './style.scss'

interface AutocompleteProps {
  name: string
  label?: string
  labelPlural?: string
  value?: number | null
  values?: any[] // when multiselect
  required?: boolean
  options: { id: number | string; name: string }[]
  optionsSortBy?: string | Array<string>
  className?: string
  placeholder?: string
  error?: string
  autoFocus?: boolean
  disabled?: boolean
  loading?: boolean
  onChange: (e: any) => void
}

const VISIBLE_COUNT = 40

const Autocomplete = observer(
  class Autocomplete extends React.Component<AutocompleteProps> {
    options: { id: number | string; name: string; disabled?: boolean }[] = []
    text: string = ''
    initialized = false // helper for loading state

    constructor(props: AutocompleteProps) {
      super(props)

      makeObservable(this, {
        options: observable,
        text: observable,
        initialized: observable
      })
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps = action((newProps: AutocompleteProps) => {
      // if (typeof newProps.value !== 'undefined' && !newProps.value) {
      //   this.text = ''
      // }
      // if (typeof newProps.values !== 'undefined' && newProps.values && !newProps.values.length) {
      //   this.text = ''
      // }
      if (!this.props.loading && newProps.loading) {
        this.initialized = true
      }
      if (this.props.options.length && !newProps.options.length) {
        this.handleResetValue()
      }
    })

    get textFieldValue() {
      return this.text
    }

    get label() {
      let { label, labelPlural, loading, values, value } = this.props
      label = labelPlural || `${label}${this.props.value ? '' : 's'}`

      let maxLength = 20 // maximum number of characters without overflowing the inpit

      if (loading) {
        return `Loading ${label}...`
      }

      if (values && values.length === 1) {
        const selectedValue = values[0]
        const selected = this.props.options.find((option) => option.id === selectedValue)
        return selected && selected.name.substring(0, maxLength).trim() + '...'
      }

      if (values && values.length) {
        return `${label} (${values.length} selected)`
      }

      if (value) {
        const selected = this.props.options.find((option) => option.id === value)
        return selected && selected.name.substring(0, maxLength).trim() + '...'
      }

      if (this.initialized && !this.props.options.length) {
        return `No ${label} found`
      }

      return label
    }

    get filteredOptions() {
      const value = this.text.trim().toLowerCase()
      const sortBy = this.props.optionsSortBy

      let options = _.sortBy(this.props.options, sortBy ? sortBy : 'name')

      if (value) {
        options = options.filter(
          (suggestion: any) => suggestion.name.trim().toLowerCase().indexOf(value) > -1
        )
      }

      // show selected items in first place
      let values = this.props.values
      if (values) {
        options = options
          .filter((option) => _.includes(values, option.id))
          .concat(options.filter((option) => !_.includes(values, option.id)))
      }

      return options
    }

    isOptionSelected = (id: number | string) => {
      return this.props.values
        ? this.props.values.indexOf(`${id}`) >= 0 || this.props.values.indexOf(Number(id)) >= 0
        : false
    }

    handleSuggestionsFetchRequested = action(() => {
      this.options = this.filteredOptions.splice(0, VISIBLE_COUNT)
    })

    handleSuggestionsClearRequested = action(() => {
      this.options = []
    })

    handleChange = action((event: any) => {
      if (this.props.values) {
        this.text = ''
      }

      if (event.target.value === undefined) return
      this.text = event.target.value

      if (this.props.value && event.target.value.length === 0) {
        this.handleResetValue()
      }
    })

    handleBlurInput = action(() => {
      this.text = ''
    })

    handleValueSelected = action((id: number | string) => {
      let values: any = null

      if (this.props.values) {
        values = this.props.values
        if (this.isOptionSelected(id)) {
          values = _.without(values, `${id}`)
          values = _.without(values, Number(id))
        } else {
          values.push(id)
        }
      } else {
        values = id
        this.text = (_.find(this.props.options, { id }) as any).name
      }

      // close autocomplete after selection, alwaysRenderSug. is not working like expected
      setTimeout(() => {
        this.text = ''
        this.handleSuggestionsClearRequested()
      }, 100)

      this.props.onChange({ target: { name: this.props.name, value: values } })
    })

    handleResetValue = action(() => {
      this.text = ''
      this.initialized = false
      this.props.onChange({
        target: {
          name: this.props.name,
          value: typeof this.props.value === 'undefined' ? [] : null
        }
      })
    })

    render() {
      const { name, values, error, placeholder, disabled, autoFocus, className } = this.props

      return (
        <div className="autocomplete">
          <Autosuggest
            suggestions={this.options.slice()}
            renderInputComponent={(props) => {
              const { ref, value, required, placeholder, disabled, onChange, ...other } = props
              const otherProps = _.pick(other, ['onFocus', 'onBlur', 'onKeyDown', 'role'])

              return (
                <TextField
                  inputProps={{ ...otherProps }}
                  InputProps={{
                    endAdornment:
                      values && values.length ? (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={() => this.handleResetValue()}
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        false
                      )
                  }}
                  name={name}
                  placeholder={placeholder}
                  label={this.label}
                  inputRef={ref}
                  value={value}
                  className={props.className}
                  required={required}
                  disabled={disabled}
                  variant="outlined"
                  margin="dense"
                  autoComplete="off"
                  fullWidth
                  onChange={onChange as any}
                  onBlur={this.handleBlurInput}
                />
              )
            }}
            inputProps={{
              autoFocus,
              onChange: this.handleChange,
              className,
              value: this.textFieldValue,
              // @ts-ignore
              error: !!error,
              required: this.props.required,
              placeholder,
              disabled
            }}
            renderSuggestion={(suggestion) => (
              <MenuItem
                component="div"
                onClick={() => this.handleValueSelected(suggestion.id)}
                className="testik"
              >
                {values && <Checkbox checked={this.isOptionSelected(suggestion.id)} />}
                <label>{suggestion.name}</label>
              </MenuItem>
            )}
            renderSuggestionsContainer={({ containerProps, children }) => (
              <Paper {...containerProps} square>
                {children}
                {children && this.filteredOptions.length > VISIBLE_COUNT && (
                  <MenuItem disabled>
                    ...and {this.filteredOptions.length - VISIBLE_COUNT} more results
                  </MenuItem>
                )}
              </Paper>
            )}
            getSuggestionValue={(suggestion) => suggestion.id.toString()}
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
            alwaysRenderSuggestions
            focusInputOnSuggestionClick={false}
          />
          {error && <FormHelperText className="input-error">{error}</FormHelperText>}
        </div>
      )
    }
  }
)

export default Autocomplete
