import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
//@ts-ignore
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory({ basename: '' })
const reactPlugin = new ReactPlugin()
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
})
ai.loadAppInsights();
export default (Component: any) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights
