import * as React from 'react'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from '@material-ui/core'
import { Delete, Publish as FileUpload } from '@material-ui/icons'
import _ from 'lodash'

import './styles.scss'

interface Props {
  name: string
  value: File[]
  error?: string
  required?: boolean
  onChange: (name: string, value: any) => void
}

export default class Uploader extends React.Component<Props> {
  constructor(props: any) {
    super(props)

    this.handleAdd = this.handleAdd.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.propagateChange = this.propagateChange.bind(this)
  }

  handleAdd(event: any) {
    let file: File = event.target.files[0]
    // clicking cancel in input form triggers change event...
    if (file) {
      let next = [...this.props.value, file]
      this.propagateChange(next)
    }
  }

  handleRemove(index: number) {
    let { value: files } = this.props
    files = [..._.take(files, index), ..._.drop(files, index + 1)]
    this.propagateChange(files)
  }

  propagateChange(files: File[]) {
    this.props.onChange(this.props.name, files)
  }

  render() {
    let { value: files } = this.props

    return (
      <div className="file-uploader">
        {
          <List
            className="file-list"
            subheader={
              <ListSubheader className="subheader" component="div">
                Attachments
              </ListSubheader>
            }
          >
            {files &&
              files.map((file, index) => (
                <ListItem button key={file.name}>
                  <ListItemText primary={file.name} />
                  <ListItemSecondaryAction>
                    <IconButton aria-label="Delete" onClick={() => this.handleRemove(index)}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        }
        {/* an elegant hack - the label click is captured by input, it's not the case with button element */}
        <Button
          className="file-uploader--button"
          variant="contained"
          color="default"
          component="label"
        >
          Add File
          <input
            type="file"
            className="hidden"
            accept="image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={this.handleAdd}
          />
          <FileUpload />
        </Button>
      </div>
    )
  }
}
